/**
 * Transparency Law Objects
 * {
 *   key: kebab casing of state transparency law name
 *   name: full formal name
 *   statute: legal name/pointer
 *   abbr: abbreviation of transparency law
 *   state: lowercase abbreviation of applicable state
 *   responseWithinDays: number of days a RAO must respond within
 *   mrGuideUrl: MuckRock open records guide link
 *   rcGuideUrl: Reporter Committee open records guide link
 * }[]
 */

export default [
  {
    key: "al-public-records",
    name: "Alabama Public Records",
    statute: "2006 Alabama Code - Section 36-12-40",
    abbr: "PR",
    state: "al",
    responseWithinDays: null,
    mrGuideUrl:
      "https://www.muckrock.com/place/united-states-of-america/alabama/",
    rcGuideUrl:
      "https://www.rcfp.org/open-government-guide/alabama/#open-records",
  },
  {
    key: "ak-public-records-act",
    name: "Alaska Public Records Act",
    statute: "AS § 40.25.110 et seq.",
    abbr: "PRA",
    state: "ak",
    responseWithinDays: null,
    mrGuideUrl:
      "https://www.muckrock.com/place/united-states-of-america/alaska/",
    rcGuideUrl:
      "https://www.rcfp.org/open-government-guide/alaska/#open-records",
  },
  {
    key: "az-public-records-law",
    name: "Arizona Public Records Law",
    statute: "A.R.S. §§ 39-101 to -161",
    abbr: "PRA",
    state: "az",
    responseWithinDays: null,
    mrGuideUrl:
      "https://www.muckrock.com/place/united-states-of-america/arizona/",
    rcGuideUrl:
      "https://www.rcfp.org/open-government-guide/arizona/#open-records",
  },
  {
    key: "ar-freedom-of-information-act",
    name: "Arkansas Freedom of Information Act",
    statute: "Ark. Code Ann. § § 25-19-101 to 25-19-109",
    abbr: "FOIA",
    state: "ar",
    responseWithinDays: 3,
    mrGuideUrl:
      "https://www.muckrock.com/place/united-states-of-america/arkansas/",
    rcGuideUrl:
      "https://www.rcfp.org/open-government-guide/arkansas/#open-records",
  },
  {
    key: "ca-public-records-act",
    name: "California Public Records Act",
    statute: "Cal. Gov't Code, Chapter 3.5 Inspection of Public Records",
    abbr: "PRA",
    state: "ca",
    responseWithinDays: 10,
    mrGuideUrl:
      "https://www.muckrock.com/place/united-states-of-america/california/",
    rcGuideUrl:
      "https://www.rcfp.org/open-government-guide/california/#open-records",
  },
  {
    key: "co-open-records-act",
    name: "Colorado Open Records Act",
    statute: "CRS 24-72-200",
    abbr: "CORA",
    state: "co",
    responseWithinDays: null,
    mrGuideUrl:
      "https://www.muckrock.com/place/united-states-of-america/colorado/",
    rcGuideUrl:
      "https://www.rcfp.org/open-government-guide/colorado/#open-records",
  },
  {
    key: "ct-freedom-of-information-act",
    name: "Connecticut Freedom of Information Act",
    statute: "Conn.Gen.Stat.§1-200 et seq",
    abbr: "FOIA",
    state: "ct",
    responseWithinDays: 4,
    mrGuideUrl:
      "https://www.muckrock.com/place/united-states-of-america/connecticut/",
    rcGuideUrl:
      "https://www.rcfp.org/open-government-guide/connecticut/#open-records",
  },
  {
    key: "de-freedom-of-information-act",
    name: "Delaware Freedom of Information Act",
    statute: "29 Del. C. § 10001 et seq.",
    abbr: "FOIA",
    state: "de",
    responseWithinDays: 15,
    mrGuideUrl:
      "https://www.muckrock.com/place/united-states-of-america/delaware/",
    rcGuideUrl:
      "https://www.rcfp.org/open-government-guide/delaware/#open-records",
  },
  {
    key: "dc-freedom-of-information-act",
    name: "DC Freedom of Information Act",
    statute: "Code §§ 2-531-539",
    abbr: "FOIA",
    state: "dc",
    responseWithinDays: 15,
    mrGuideUrl:
      "https://www.muckrock.com/place/united-states-of-america/district-of-columbia/",
    rcGuideUrl:
      "https://www.rcfp.org/open-government-guide/district-of-columbia/#open-records",
  },
  {
    key: "fl-sunshine-law",
    name: "Florida Sunshine Law",
    statute: "Florida Statutes, Title X, Chapter 119",
    abbr: "SL",
    state: "fl",
    responseWithinDays: null,
    mrGuideUrl:
      "https://www.muckrock.com/place/united-states-of-america/florida/",
    rcGuideUrl:
      "https://www.rcfp.org/open-government-guide/florida/#open-records",
  },
  {
    key: "ga-open-records-act",
    name: "Georgia Open Records Act",
    statute: "Georgia Law § 50-18-70",
    abbr: "ORA",
    state: "ga",
    responseWithinDays: 3,
    mrGuideUrl:
      "https://www.muckrock.com/place/united-states-of-america/georgia/",
    rcGuideUrl:
      "https://www.rcfp.org/open-government-guide/georgia/#open-records",
  },
  {
    key: "hi-uniform-information-practices-act",
    name: "Hawaii Uniform Information Practices Act",
    statute: "Chapter 92F, Hawaii Revised Statutes",
    abbr: "UIPA",
    state: "hi",
    responseWithinDays: null,
    mrGuideUrl:
      "https://www.muckrock.com/place/united-states-of-america/hawaii/",
    rcGuideUrl:
      "https://www.rcfp.org/open-government-guide/hawaii/#open-records",
  },
  {
    key: "id-public-records-act",
    name: "Idaho Public Records Act",
    statute: "Idaho Code §§ 74-101",
    abbr: "PRA",
    state: "id",
    responseWithinDays: 3,
    mrGuideUrl:
      "https://www.muckrock.com/place/united-states-of-america/idaho/",
    rcGuideUrl:
      "https://www.rcfp.org/open-government-guide/idaho/#open-records",
  },
  {
    key: "il-freedom-of-information-act",
    name: "Illinois Freedom of Information Act",
    statute: "(5 ILCS 140)",
    abbr: "FOIA",
    state: "il",
    responseWithinDays: null,
    mrGuideUrl:
      "https://www.muckrock.com/place/united-states-of-america/illinois/",
    rcGuideUrl:
      "https://www.rcfp.org/open-government-guide/illinois/#open-records",
  },
  {
    key: "in-access-to-public-records-act",
    name: "Indiana Access to Public Records Act",
    statute: "Ind.Code Ann. 5-14-3-1 to 10",
    abbr: "APRA",
    state: "in",
    responseWithinDays: 7,
    mrGuideUrl:
      "https://www.muckrock.com/place/united-states-of-america/indiana/",
    rcGuideUrl:
      "https://www.rcfp.org/open-government-guide/indiana/#open-records",
  },
  {
    key: "ia-open-records-law",
    name: "Iowa Open Records Law",
    statute: "Iowa Code Ann. 22.1 to .14",
    abbr: "ORA",
    state: "ia",
    responseWithinDays: null,
    mrGuideUrl: "https://www.muckrock.com/place/united-states-of-america/iowa/",
    rcGuideUrl: "https://www.rcfp.org/open-government-guide/iowa/#open-records",
  },
  {
    key: "ks-open-records-act",
    name: "Kansas Open Records Act",
    statute: "Kan.Stat.Ann 45-215 to 225",
    abbr: "ORA",
    state: "ks",
    responseWithinDays: 3,
    mrGuideUrl:
      "https://www.muckrock.com/place/united-states-of-america/kansas/",
    rcGuideUrl:
      "https://www.rcfp.org/open-government-guide/kansas/#open-records",
  },
  {
    key: "ky-open-records-act",
    name: "Kentucky Open Records Act",
    statute: "Ky.Rev.Stat..Ann. 61.870 to .884",
    abbr: "ORA",
    state: "ky",
    responseWithinDays: null,
    mrGuideUrl:
      "https://www.muckrock.com/place/united-states-of-america/kentucky/",
    rcGuideUrl:
      "https://www.rcfp.org/open-government-guide/kentucky/#open-records",
  },
  {
    key: "la-public-records-law",
    name: "Louisiana Public Records Law",
    statute: "Louisiana Revised Statutes Title 44",
    abbr: "PRL",
    state: "la",
    responseWithinDays: 3,
    mrGuideUrl:
      "https://www.muckrock.com/place/united-states-of-america/louisiana/",
    rcGuideUrl:
      "https://www.rcfp.org/open-government-guide/louisiana/#open-records",
  },
  {
    key: "me-freedom-of-access-act",
    name: "Maine Freedom of Access Act",
    statute: "1 M.R.S §400",
    abbr: "FOAA",
    state: "me",
    responseWithinDays: 5,
    mrGuideUrl:
      "https://www.muckrock.com/place/united-states-of-america/maine/",
    rcGuideUrl:
      "https://www.rcfp.org/open-government-guide/maine/#open-records",
  },
  {
    key: "md-public-information-act",
    name: "Maryland Public Information Act",
    statute: "Md. Ann. Code art. GP, § 4-101",
    abbr: "PIA",
    state: "md",
    responseWithinDays: 30,
    mrGuideUrl:
      "https://www.muckrock.com/place/united-states-of-america/maryland/",
    rcGuideUrl:
      "https://www.rcfp.org/open-government-guide/maryland/#open-records",
  },
  {
    key: "ma-public-records-law",
    name: "Massachusetts Public Records Law",
    statute: "Massachusetts General Laws, Part 1, Title X, Chapter 66",
    abbr: "PRL",
    state: "ma",
    responseWithinDays: null,
    mrGuideUrl:
      "https://www.muckrock.com/place/united-states-of-america/massachusetts/",
    rcGuideUrl:
      "https://www.rcfp.org/open-government-guide/massachusetts/#open-records",
  },
  {
    key: "mi-freedom-of-information-act",
    name: "Michigan Freedom of Information Act",
    statute: "MCL 15.231 et. seq.",
    abbr: "FOIA",
    state: "mi",
    responseWithinDays: 5,
    mrGuideUrl:
      "https://www.muckrock.com/place/united-states-of-america/michigan/",
    rcGuideUrl:
      "https://www.rcfp.org/open-government-guide/michigan/#open-records",
  },
  {
    key: "mn-government-data-practices-act",
    name: "Minnesota Government Data Practices Act",
    abbr: "MGDPA",
    state: "mn",
    responseWithinDays: null,
    mrGuideUrl:
      "https://www.muckrock.com/place/united-states-of-america/minnesota/",
    rcGuideUrl:
      "https://www.rcfp.org/open-government-guide/minnesota/#open-records",
  },
  {
    key: "ms-public-records-act",
    name: "Mississippi Public Records Act",
    statute: "Miss. Code Ann. 25-61-1 et seq",
    abbr: "PRA",
    state: "ms",
    responseWithinDays: 7,
    mrGuideUrl:
      "https://www.muckrock.com/place/united-states-of-america/mississippi/",
    rcGuideUrl:
      "https://www.rcfp.org/open-government-guide/mississippi/#open-records",
  },
  {
    key: "mo-sunshine-law",
    name: "Missouri Sunshine Law",
    statute: "Chapter 610 of the Revised Statutes of Missouri",
    abbr: "SL",
    state: "mo",
    responseWithinDays: 3,
    mrGuideUrl:
      "https://www.muckrock.com/place/united-states-of-america/missouri/",
    rcGuideUrl:
      "https://www.rcfp.org/open-government-guide/missouri/#open-records",
  },
  {
    key: "mt-freedom-of-information-act",
    name: "Montana Freedom of Information Act",
    statute: "Mont.Code Ann. 2-6-1",
    abbr: "FOIA",
    state: "mt",
    responseWithinDays: null,
    mrGuideUrl:
      "https://www.muckrock.com/place/united-states-of-america/montana/",
    rcGuideUrl:
      "https://www.rcfp.org/open-government-guide/montana/#open-records",
  },
  {
    key: "ne-public-records-act",
    name: "Nebraska Public Records Act",
    statute: "Neb. Rev. Stat. §§ 84-712 - 84-712.09",
    abbr: "PRA",
    state: "ne",
    responseWithinDays: 4,
    mrGuideUrl:
      "https://www.muckrock.com/place/united-states-of-america/nebraska/",
    rcGuideUrl:
      "https://www.rcfp.org/open-government-guide/nebraska/#open-records",
  },
  {
    key: "nv-public-records-act",
    name: "Nevada Public Records Act",
    statute: "N.R.S. 239.010",
    abbr: "PRA",
    state: "nv",
    responseWithinDays: 5,
    mrGuideUrl:
      "https://www.muckrock.com/place/united-states-of-america/nevada/",
    rcGuideUrl:
      "https://www.rcfp.org/open-government-guide/nevada/#open-records",
  },
  {
    key: "nh-right-to-know-law",
    name: "New Hampshire Right to Know Law",
    statute: "New Hampshire RSA Ch. 91-A",
    abbr: "RTK",
    state: "nh",
    responseWithinDays: 5,
    mrGuideUrl:
      "https://www.muckrock.com/place/united-states-of-america/new-hampshire/",
    rcGuideUrl:
      "https://www.rcfp.org/open-government-guide/new-hampshire/#open-records",
  },
  {
    key: "nm-inspection-of-public-records-act",
    name: "New Mexico Inspection of Public Records Act",
    statute: "14-2-1 NMSA 1978 et seq.",
    abbr: "IPRA",
    state: "nm",
    responseWithinDays: null,
    mrGuideUrl:
      "https://www.muckrock.com/place/united-states-of-america/new-mexico/",
    rcGuideUrl:
      "https://www.rcfp.org/open-government-guide/new-mexico/#open-records",
  },
  {
    key: "nc-public-records-law",
    name: "North Carolina Public Records Law",
    abbr: "PRL",
    state: "nc",
    responseWithinDays: null,
    mrGuideUrl:
      "https://www.muckrock.com/place/united-states-of-america/north-carolina/",
    rcGuideUrl:
      "https://www.rcfp.org/open-government-guide/north-carolina/#open-records",
  },
  {
    key: "nd-open-records-law",
    name: "North Dakota Open Records Law",
    statute:
      "N.D.C.C. § 44-04-18 et seq, North Dakota Constitution, Article XI, Section 6",
    abbr: "ORL",
    state: "nd",
    responseWithinDays: null,
    mrGuideUrl:
      "https://www.muckrock.com/place/united-states-of-america/north-dakota/",
    rcGuideUrl:
      "https://www.rcfp.org/open-government-guide/north-dakota/#open-records",
  },
  {
    key: "nj-open-public-records-act",
    name: "New Jersey Open Public Records Act",
    statute: "NJSA 47:1A-1 et seq.",
    abbr: "OPRA",
    state: "nj",
    responseWithinDays: 7,
    mrGuideUrl:
      "https://www.muckrock.com/place/united-states-of-america/new-jersey/",
    rcGuideUrl:
      "https://www.rcfp.org/open-government-guide/new-jersey/#open-records",
  },
  {
    key: "ny-freedom-of-information-law",
    name: "New York Freedom of Information Law",
    statute: "N.Y. Pub. Off. Law Ch. 47 Art. 6 § 84",
    abbr: "FOIL",
    state: "ny",
    responseWithinDays: 5,
    mrGuideUrl:
      "https://www.muckrock.com/place/united-states-of-america/new-york/",
    rcGuideUrl:
      "https://www.rcfp.org/open-government-guide/new-york/#open-records",
  },
  {
    key: "oh-freedom-of-information-act",
    name: "Ohio Open Records Law",
    statute: "Ohio Rev. Code sec. 149.43 et seq.",
    abbr: "ORL",
    state: "oh",
    responseWithinDays: null,
    mrGuideUrl: "https://www.muckrock.com/place/united-states-of-america/ohio/",
    rcGuideUrl: "https://www.rcfp.org/open-government-guide/ohio/#open-records",
  },
  {
    key: "ok-open-records-act",
    name: "Oklahoma Open Records Act",
    statute: "OK Title 51, Sections 24A.1-30",
    abbr: "ORA",
    state: "ok",
    responseWithinDays: null,
    mrGuideUrl:
      "https://www.muckrock.com/place/united-states-of-america/oklahoma/",
    rcGuideUrl:
      "https://www.rcfp.org/open-government-guide/oklahoma/#open-records",
  },
  {
    key: "or-public-records-law",
    name: "Oregon Public Records Law",
    statute: "Or. Rev. Stat. Ann. 192.410 to .505",
    abbr: "OPRL",
    state: "or",
    responseWithinDays: null,
    mrGuideUrl:
      "https://www.muckrock.com/place/united-states-of-america/oregon/",
    rcGuideUrl:
      "https://www.rcfp.org/open-government-guide/oregon/#open-records",
  },
  {
    key: "pa-right-to-know-act",
    name: "Pennsylvania Right to Know Act",
    statute: "Pa.Cons.Stat.Ann. Tit. 65, 66..1 to .4",
    abbr: "RTK",
    state: "pa",
    responseWithinDays: 5,
    mrGuideUrl:
      "https://www.muckrock.com/place/united-states-of-america/pennsylvania/",
    rcGuideUrl:
      "https://www.rcfp.org/open-government-guide/pennsylvania/#open-records",
  },
  {
    key: "ri-access-to-public-records-act",
    name: "Rhode Island Access to Public Records Act",
    statute: "R.I. Gen. Laws 38-2-1 to -14",
    abbr: "APRA",
    state: "ri",
    responseWithinDays: null,
    mrGuideUrl:
      "https://www.muckrock.com/place/united-states-of-america/rhode-island/",
    rcGuideUrl:
      "https://www.rcfp.org/open-government-guide/rhode-island/#open-records",
  },
  {
    key: "sc-public-records-law",
    name: "South Carolina Public Records Law",
    statute: "S.C. Code Ann. 30-4-10",
    abbr: "PRL",
    state: "sc",
    responseWithinDays: 15,
    mrGuideUrl:
      "https://www.muckrock.com/place/united-states-of-america/south-carolina/",
    rcGuideUrl:
      "https://www.rcfp.org/open-government-guide/south-carolina/#open-records",
  },
  {
    key: "sd-open-records-law",
    name: "South Dakota Open Records Law",
    statute: "S.D. Codified Laws Ann. 1-25-1 to -19",
    abbr: "SDORL",
    state: "sd",
    responseWithinDays: null,
    mrGuideUrl:
      "https://www.muckrock.com/place/united-states-of-america/south-dakota/",
    rcGuideUrl:
      "https://www.rcfp.org/open-government-guide/south-dakota/#open-records",
  },
  {
    key: "tn-public-records-act",
    name: "Tennessee Public Records Act",
    statute: "Tenn. Code Ann. § 10-7-501 et seq.",
    abbr: "TPRA",
    state: "tn",
    responseWithinDays: 7,
    mrGuideUrl:
      "https://www.muckrock.com/place/united-states-of-america/tennessee/",
    rcGuideUrl:
      "https://www.rcfp.org/open-government-guide/tennessee/#open-records",
  },
  {
    key: "tx-public-information-act",
    name: "Texas Public Information Act",
    statute:
      "Texas Government Code, Title 5, Subtitle A, Chapter 552, Subchapter A",
    abbr: "PIA",
    state: "tx",
    responseWithinDays: 10,
    mrGuideUrl:
      "https://www.muckrock.com/place/united-states-of-america/texas/",
    rcGuideUrl:
      "https://www.rcfp.org/open-government-guide/texas/#open-records",
  },
  {
    key: "ut-government-records-access-and-management-act",
    name: "Utah Government Records Access and Management Act",
    statute: "Utah Government Records Access and Management Act 63G-2-201",
    abbr: "GRAMA",
    state: "ut",
    responseWithinDays: 10,
    mrGuideUrl: "https://www.muckrock.com/place/united-states-of-america/utah/",
    rcGuideUrl: "https://www.rcfp.org/open-government-guide/utah/#open-records",
  },
  {
    key: "vt-public-records-act",
    name: "Vermont Public Records Act",
    statute: "1 V.S.A. Sec. 315-320",
    abbr: "PRA",
    state: "vt",
    responseWithinDays: null,
    mrGuideUrl:
      "https://www.muckrock.com/place/united-states-of-america/vermont/",
    rcGuideUrl:
      "https://www.rcfp.org/open-government-guide/vermont/#open-records",
  },
  {
    key: "va-freedom-of-information-act",
    name: "Virginia Freedom of Information Act",
    statute: "§ 2.2-3700",
    abbr: "FOIA",
    state: "va",
    responseWithinDays: 5,
    mrGuideUrl:
      "https://www.muckrock.com/place/united-states-of-america/virginia/",
    rcGuideUrl:
      "https://www.rcfp.org/open-government-guide/virginia/#open-records",
  },
  {
    key: "wa-public-records-act",
    name: "Washington Public Records Act",
    statute: "Wash. Rev. Code Ann. 42.56.001 to .904",
    abbr: "PRA",
    state: "wa",
    responseWithinDays: 5,
    mrGuideUrl:
      "https://www.muckrock.com/place/united-states-of-america/washington/",
    rcGuideUrl:
      "https://www.rcfp.org/open-government-guide/washington/#open-records",
  },
  {
    key: "wv-freedom-of-information-act",
    name: "West Virginia Freedom of Information Act",
    statute: "W.Va. Code§ 6-9A-1",
    abbr: "FOIA",
    state: "wv",
    responseWithinDays: 5,
    mrGuideUrl:
      "https://www.muckrock.com/place/united-states-of-america/west-virginia/",
    rcGuideUrl:
      "https://www.rcfp.org/open-government-guide/west-virginia/#open-records",
  },
  {
    key: "wi-open-records-act",
    name: "Wisconsin Open Records Act",
    statute: "Wis. Stat. Ann. 19.31 to .39",
    abbr: "FOIA",
    state: "wi",
    responseWithinDays: null,
    mrGuideUrl:
      "https://www.muckrock.com/place/united-states-of-america/wisconsin/",
    rcGuideUrl:
      "https://www.rcfp.org/open-government-guide/wisconsin/#open-records",
  },
  {
    key: "wy-public-records-act",
    name: "Wyoming Public Records Act",
    abbr: "FOIA",
    state: "wy",
    responseWithinDays: null,
    mrGuideUrl:
      "https://www.muckrock.com/place/united-states-of-america/wyoming/",
    rcGuideUrl:
      "https://www.rcfp.org/open-government-guide/wyoming/#open-records",
  },
]
